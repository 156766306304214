<template>
  <page-login-layout>
    <form-login-video
      :stream="stream"
      v-if="isStreamActive"
      @submit="handleSubmitEvent"
      @cancel="disposeVideoStream"
      @loggedin="handleLoggedInEvent"
      :authentication-type="authenticationType"
    />
    <page-login-card v-else>
      <page-login-logo slot="logo" />
      <page-login-header slot="header" :authentication-type="authenticationType" />
      <page-login-video-stream-button
        slot="video_stream_button"
        @click="tryVideoStreamCreating"
        v-if="isVideoStreamButtonVisible"
        :disabled="isVideoStreamButtonDisabled"
      />
      <page-login-video-stream-alternative-hint slot="video_stream_alternative_hint" v-if="isVideoStreamAlternativeHintVisible" />
      <form-login-basic slot="form_login_basic" @submit="handleSubmitEvent" :loading="isLoading" v-if="isBasicLoginFormVisible" />
    </page-login-card>
  </page-login-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component, Vue } from 'vue-property-decorator';
import FormLoginBasic from './form-login-basic';
import FormLoginVideo from './form-login-video';
import { createVideoStream, disposeVideoStream, doesUserAgentSupportVideoRecording } from './media-devices-helpers';
import PageLoginCard from './page-login-card';
import PageLoginHeader from './page-login-header';
import { AuthenticationType, isVideoAuthenticationEnabled, notifyOnError } from './page-login-helpers';
import PageLoginLayout from './page-login-layout';
import PageLoginLogo from './page-login-logo';
import PageLoginVideoStreamAlternativeHint from './page-login-video-stream-alternative-hint';
import PageLoginVideoStreamButton from './page-login-video-stream-button';
import { AppErrors } from '@/components/notification/erros';

const DomExceptionNameErrorKeyMap = {
  NotAllowedError: AppErrors.WebcamNotAllowed,
  NotFoundError: AppErrors.WebcamNotAvailable,
  OverconstrainedError: AppErrors.WebcamNotAvailable,
  NotReadableError: AppErrors.WebcamNotReadable
};

@Component({
  components: {
    FormLoginBasic,
    FormLoginVideo,
    PageLoginCard,
    PageLoginHeader,
    PageLoginLayout,
    PageLoginLogo,
    PageLoginVideoStreamAlternativeHint,
    PageLoginVideoStreamButton
  }
})
export default class PageLogin extends Vue {
  stream = null;
  isLoading = false;
  isVideoStreamButtonDisabled = false;

  get state() {
    return this.$store.state.users;
  }

  get isStreamActive() {
    return !!this.stream?.active;
  }

  get authenticationType() {
    return hasSecureConnection() ? this.getConfiguredAuthenticationType() : AuthenticationType.PASSWORD;
  }

  getConfiguredAuthenticationType() {
    const authenticationType = this.$store.state.config?.auth?.AUTH_TYPE;
    return _.isString(authenticationType) ? authenticationType : AuthenticationType.PASSWORD;
  }

  get isBasicLoginFormVisible() {
    return [AuthenticationType.PASSWORD, AuthenticationType.FACE_OR_PASSWORD].includes(this.authenticationType);
  }

  get isVideoStreamButtonVisible() {
    return isVideoAuthenticationEnabled(this.authenticationType);
  }

  get isVideoStreamAlternativeHintVisible() {
    return this.authenticationType === AuthenticationType.FACE_OR_PASSWORD;
  }

  beforeDestroy() {
    this.disposeVideoStream();
  }

  tryVideoStreamCreating() {
    if (doesUserAgentSupportVideoRecording()) {
      return this.createVideoStream();
    }
    this.notifyThatUserAgentDoesntSupportVideoRecording();
  }

  async createVideoStream() {
    try {
      this.isVideoStreamButtonDisabled = true;
      this.stream = await createVideoStream();
    } catch (e) {
      this.handleVideoStreamCreatingError(e);
    } finally {
      this.isVideoStreamButtonDisabled = false;
    }
  }

  handleVideoStreamCreatingError(e) {
    const appErrorName = computeDomExceptionErrorKey(e.name);
    appErrorName ? this.$showError({ name: appErrorName }) : notifyOnError(this, e);
  }

  disposeVideoStream() {
    if (this.isStreamActive) {
      this.stream = (disposeVideoStream(this.stream), null);
    }
  }

  async handleSubmitEvent(payload) {
    this.isLoading = true;
    try {
      const loggedInUser = await this.login(payload);
      await this.handleLoggedInEvent(loggedInUser);
    } catch (e) {
      notifyOnError(this, e);
    } finally {
      this.isLoading = false;
    }
  }

  async handleLoggedInEvent(loggedInUser) {
    try {
      await this.$store.dispatch(this.state.Action.LoadLoggedInUser, loggedInUser);
      this.$router.push({ path: this.$store.getters.defaultRoutePath });
      this.notifyThatUserWasLoggedIn();
    } catch (e) {
      notifyOnError(this, e);
    }
  }

  login(payload) {
    return this.$store.dispatch(this.$store.state.users.Action.Login, payload);
  }

  notifyThatUserWasLoggedIn() {
    this.$showSuccess({
      message: this.$tf(['common.login,,1', 'common.success,,1'])
    });
  }

  notifyThatUserAgentDoesntSupportVideoRecording() {
    this.$notify.error({ message: this.$tf('video_recording_is_not_supported') });
  }
}

function hasSecureConnection() {
  return location.hostname === 'localhost' || location.protocol === 'https:';
}

function computeDomExceptionErrorKey(exceptionName) {
  return DomExceptionNameErrorKeyMap[exceptionName] ?? null;
}
</script>
